import './Footer.css'
import {
  AiOutlineMail,
  // AiOutlineFilePdf,
  AiOutlineTwitter,
  AiOutlineGithub,
} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer className='footer'>
      <ul className='footer-links'>
        <li className='footer-link'>
          <a
            target='_blank'
            rel='noreferrer'
            href='mailto:jasonfrancki@gmail.com'
          >
            <AiOutlineMail />
          </a>
        </li>
        {/* <li className="footer-link">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.jasonfrancki.com/"
          >
            <AiOutlineFilePdf />
          </a>
        </li> */}
        <li className='footer-link'>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://github.com/jasonfrancki'
          >
            <AiOutlineGithub />
          </a>
        </li>
        {/* <li className='footer-link'>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://twitter.com/JasonChasinJSON'
          >
            <AiOutlineTwitter />
          </a>
        </li> */}
      </ul>
    </footer>
  )
}

export default Footer
